import SidebarWithHeader from 'admin/components/sidebar'
import FoodTable from 'admin/components/tables/food/foodTable'
import { deleteWeeklyItem, getAllWeekly, postWeeklyForm, updateWeeklyForm } from 'admin/components/tables/food/functions'
import React from 'react'

const AdminWeeklyPage = () => {
    return (
        <SidebarWithHeader>
            <FoodTable
                getAllFunc={getAllWeekly}
                postFunc={postWeeklyForm}
                updateFunc={updateWeeklyForm}
                deleteFunc={deleteWeeklyItem}
                title={'Heti menü'}
            ></FoodTable>
        </SidebarWithHeader>
    )
}

export default AdminWeeklyPage
