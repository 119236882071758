import SidebarWithHeader from 'admin/components/sidebar'
import CategoryTable from 'admin/components/tables/category/categoryTable'
import {
    deleteDrinksCategory,
    getAllDrinksCategories,
    postDrinksCategory,
    updateDrinksCategory,
} from 'admin/components/tables/category/drinkFunctions'
import {} from 'admin/components/tables/category/foodFunctions'
import React from 'react'

const AdminDrinksCategoriesPage = () => {
    return (
        <SidebarWithHeader>
            <CategoryTable
                getAllFunc={getAllDrinksCategories}
                postFunc={postDrinksCategory}
                updateFunc={updateDrinksCategory}
                deleteFunc={deleteDrinksCategory}
                title={'Ital kategóriák'}
            ></CategoryTable>
        </SidebarWithHeader>
    )
}

export default AdminDrinksCategoriesPage
