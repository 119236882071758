import { FormControl, FormLabel, Input, ModalBody, ModalCloseButton, ModalFooter, ModalHeader, Select } from '@chakra-ui/react'
import SubmitButtons from 'admin/components/customModal/submitButtons'
import React, { useState } from 'react'
import { categoryProps, drinksProps, PriceArrayProps } from 'types'

import UnitControl from './unitControl'

interface Props {
    onClose?: () => void
    modalName?: string
    categoriesList: categoryProps[]
    updateForm?: (
        id: string,
        index: string,
        name: string,
        description: string,
        englishName: string,
        englishDescription: string,
        priceArray: PriceArrayProps[],
        category: string
    ) => void
    postForm?: (
        name: string,
        description: string,
        englishName: string,
        englishDescription: string,
        priceArray: PriceArrayProps[],
        category: string
    ) => void
    data?: drinksProps
}

const priceDefault: Array<PriceArrayProps> = [
    {
        id: 0,
        quantity: '',
        unit: '',
        price: '',
    },
]

const DrinksForm = ({ onClose, modalName, categoriesList, data, updateForm, postForm }: Props) => {
    const [name, setName] = useState(data?.name || '')
    const [description, setDescription] = useState(data?.description || '')
    const [englishName, setEnglishName] = useState(data?.english?.name || '')
    const [englishDescription, setEnglishDescription] = useState(data?.english?.description || '')
    const [category, setCategory] = useState(data?.category._id || '')
    const [priceArray, setPriceArray] = useState(data?.priceArray || priceDefault)

    const handleSubmit = async (event: { preventDefault: () => void }) => {
        event.preventDefault()
        if (data) updateForm && updateForm(data._id, data.index, name, description, englishName, englishDescription, priceArray, category)
        else postForm && postForm(name, description, englishName, englishDescription, priceArray, category)
        onClose && onClose()
    }

    return (
        <>
            <form onSubmit={handleSubmit}>
                <ModalHeader>{modalName}</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                    <FormControl>
                        <FormLabel>Név</FormLabel>
                        <Input placeholder="Név" defaultValue={name} onChange={(e) => setName(e.target.value)} />
                    </FormControl>
                    <FormControl mt={4}>
                        <FormLabel>Angol név</FormLabel>
                        <Input placeholder="Angol név" defaultValue={englishName} onChange={(e) => setEnglishName(e.target.value)} />
                    </FormControl>
                    <FormControl mt={4}>
                        <FormLabel>Leírás</FormLabel>
                        <Input placeholder="Leírás" defaultValue={description} onChange={(e) => setDescription(e.target.value)} />
                    </FormControl>
                    <FormControl mt={4}>
                        <FormLabel>Angol leírás</FormLabel>
                        <Input
                            placeholder="Angol leírás"
                            defaultValue={englishDescription}
                            onChange={(e) => setEnglishDescription(e.target.value)}
                        />
                    </FormControl>
                    <FormControl mt={4}>
                        <FormLabel>Kategóriák</FormLabel>
                        <Select value={category} placeholder="Válassz kategóriát" onChange={(e) => setCategory(e.target.value)}>
                            {categoriesList?.map((category, index) => {
                                return (
                                    <option key={index} value={category._id}>
                                        {category?.name}
                                    </option>
                                )
                            })}
                        </Select>
                    </FormControl>
                    <UnitControl priceArray={priceArray} setPriceArray={setPriceArray}></UnitControl>
                </ModalBody>
                <ModalFooter>
                    <SubmitButtons onClose={onClose}></SubmitButtons>
                </ModalFooter>
            </form>
        </>
    )
}

export default DrinksForm
