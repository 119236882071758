import { Modal, ModalContent, ModalOverlay } from '@chakra-ui/react'
import React, { JSXElementConstructor, ReactElement } from 'react'

interface Props {
    children?: ReactElement<any, string | JSXElementConstructor<any>>
    modalName: string
    isOpen: boolean
    onClose: () => void
}

export const CustomModalEdit = ({ children, modalName, isOpen, onClose }: Props) => {
    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent> {children && React.cloneElement(children, { onClose: onClose, modalName: modalName })}</ModalContent>
            </Modal>
        </>
    )
}
