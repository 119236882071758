import { Button, Modal, ModalContent, ModalOverlay, useDisclosure } from '@chakra-ui/react'
import React, { JSXElementConstructor, ReactElement } from 'react'

interface Props {
    children?: ReactElement<any, string | JSXElementConstructor<any>>
    modalName: string
}

export const CustomModalNew = ({ children, modalName }: Props) => {
    const { isOpen, onOpen, onClose } = useDisclosure()

    return (
        <>
            <Button onClick={onOpen} background={'green.200'} _hover={{ background: 'green.400' }}>
                {modalName}
            </Button>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent> {children && React.cloneElement(children, { onClose: onClose, modalName: modalName })}</ModalContent>
            </Modal>
        </>
    )
}
