import SidebarWithHeader from 'admin/components/sidebar'
import CategoryTable from 'admin/components/tables/category/categoryTable'
import {
    deleteFoodCategory,
    getAllFoodCategories,
    postFoodCategory,
    updateFoodCategory,
} from 'admin/components/tables/category/foodFunctions'
import React from 'react'

const AdminMenuPage = () => {
    return (
        <SidebarWithHeader>
            <CategoryTable
                getAllFunc={getAllFoodCategories}
                postFunc={postFoodCategory}
                updateFunc={updateFoodCategory}
                deleteFunc={deleteFoodCategory}
                title={'Étel kategóriák'}
            ></CategoryTable>
        </SidebarWithHeader>
    )
}

export default AdminMenuPage
