import * as announcementService from 'services/announcement.service'
import { announcementProps } from 'types'

export const getAllAnnouncement = async (
    setState: React.Dispatch<React.SetStateAction<announcementProps[] | undefined>>
): Promise<void> => {
    try {
        const res = await announcementService.getAll()
        setState(res.data)
    } catch (error) {
        throw error
    }
}

export const updateAnnouncement = async (id: string, description: string, englishDescription: string, active: boolean) => {
    try {
        const res = await announcementService.update({
            _id: id,
            description: description,
            english: { description: englishDescription },
            active: active,
        })

        return res
    } catch (error) {
        throw error
    }
}

export const postAnnouncement = async (description: string, englishDescription: string) => {
    try {
        const res = await announcementService.post({
            description: description,
            english: { description: englishDescription },
            active: false,
        })

        return res
    } catch (error) {
        throw error
    }
}

export const deleteAnnouncement = async (id: string) => {
    try {
        const res = await announcementService.deleteItem({ id })

        return res
    } catch (error) {
        throw error
    }
}
